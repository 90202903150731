<i18n>
  {
    "es": {
      "SAVE": 'Guardar',
      "SAVING": 'Guardando...',
      "SAVE_CARD": 'Guardar tarjeta',
      "CANCEL": 'Cancelar',
      "NEW_CARD": "Nueva tarjeta",
      "CREDIT_OR_DEBIT_CARD": "Tarjeta de credito o debito",
      "CREDIT_OR_DEBIT_CARD_INFO": "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis",
      "ALIAS_PLACEHOLDER": "Alias",
      "OWNER_PLACEHOLDER": "Titular",
      "PAN_PLACEHOLDER": "Nº Tarjeta",
      "MONTH_PLACEHOLDER": "Expira (MM)",
      "YEAR_PLACEHOLDER": "Expira (YY)",
      "ADD_CARD": "Añadir tarjeta",
      "CREDITCARD_ADDED_SUCCEED" : "Tarjeta añadida correctamente",
      "CVV2": "CVV2",
      "SECURE_DATA": "Sus datos estan seguros. Paycomet es una plataforma PCI-DSS Nivel 1, la certificación más avanzada, que encripta los datos de tarjeta",
    },
    "en": {
      "SAVING": 'Saving...',
      "SAVE": 'Save',
      "SAVE_CARD": 'Save card',
      "CANCEL": 'Cancel',
      "NEW_CARD": "New card",
      "CREDIT_OR_DEBIT_CARD": "Credit or debit card",
      "CREDIT_OR_DEBIT_CARD_INFO": "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis",
      "ALIAS_PLACEHOLDER": "Alias",
      "OWNER_PLACEHOLDER": "Owner",
      "PAN_PLACEHOLDER": "Card Number",
      "MONTH_PLACEHOLDER": "Expires (MM)",
      "YEAR_PLACEHOLDER": "Expires (YY)",
      "ADD_CARD": "Add card",
      "CREDITCARD_ADDED_SUCCEED" : "Card added successfully",
      "CVV2": "CVV2",
      "SECURE_DATA": "Your data is secure. Paycomet is a PCI-DSS Level 1 platform, the most advanced certification, which encrypts card data",
    },
    "fr": {
      "SAVING": 'Enregistrement...',
      "SAVE": 'Enregistrer',
      "SAVE_CARD": 'Enregistrer la carte',
      "CANCEL": 'Annuler',
      "NEW_CARD": "Nouvelle carte",
      "CREDIT_OR_DEBIT_CARD": "Carte de crédit ou de débit",
      "CREDIT_OR_DEBIT_CARD_INFO": "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis",
      "ALIAS_PLACEHOLDER": "Alias",
      "OWNER_PLACEHOLDER": "Titulaire",
      "PAN_PLACEHOLDER": "Nº Carte",
      "MONTH_PLACEHOLDER": "Expire (MM)",
      "YEAR_PLACEHOLDER": "Expire (YY)",
      "ADD_CARD": "Ajouter une carte",
      "CREDITCARD_ADDED_SUCCEED" : "Carte ajoutée avec succès",
      "CVV2": "CVV2",
      "SECURE_DATA": "Vos données sont sécurisées. Paycomet est une plateforme de niveau 1 PCI-DSS, la certification la plus avancée, qui crypte les données de carte",
    },
    "it": {
      "SAVING": 'Salvataggio...',
      "SAVE": 'Salva',
      "SAVE_CARD": 'Salva carta',
      "CANCEL": 'Annulla',
      "NEW_CARD": "Nuova carta",
      "CREDIT_OR_DEBIT_CARD": "Carta di credito o di debito",
      "CREDIT_OR_DEBIT_CARD_INFO": "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis",
      "ALIAS_PLACEHOLDER": "Alias",
      "OWNER_PLACEHOLDER": "Proprietario",
      "PAN_PLACEHOLDER": "Nº Carta",
      "MONTH_PLACEHOLDER": "Scade (MM)",
      "YEAR_PLACEHOLDER": "Scade (YY)",
      "ADD_CARD": "Aggiungi carta",
      "CREDITCARD_ADDED_SUCCEED" : "Carta aggiunta con successo",
      "CVV2": "CVV2",
      "SECURE_DATA": "I tuoi dati sono sicuri. Paycomet è una piattaforma di livello 1 PCI-DSS, la certificazione più avanzata, che crittografa i dati della carta",
    },
    "de": {
      "SAVING": 'Speichern...',
      "SAVE": 'Speichern',
      "SAVE_CARD": 'Karte speichern',
      "CANCEL": 'Stornieren',
      "NEW_CARD": "Neue Karte",
      "CREDIT_OR_DEBIT_CARD": "Kredit- oder Debitkarte",
      "CREDIT_OR_DEBIT_CARD_INFO": "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis",
      "ALIAS_PLACEHOLDER": "Alias",
      "OWNER_PLACEHOLDER": "Inhaber",
      "PAN_PLACEHOLDER": "Kartennummer",
      "MONTH_PLACEHOLDER": "Läuft ab (MM)",
      "YEAR_PLACEHOLDER": "Läuft ab (YY)",
      "ADD_CARD": "Karte hinzufügen",
      "CREDITCARD_ADDED_SUCCEED" : "Karte erfolgreich hinzugefügt",
      "CVV2": "CVV2",
      "SECURE_DATA": "Ihre Daten sind sicher. Paycomet ist eine PCI-DSS-Stufe-1-Plattform, die fortschrittlichste Zertifizierung, die Kartendaten verschlüsselt",
    },
    "pt": {
      "SAVING": 'Salvando...',
      "SAVE": 'Salvar',
      "SAVE_CARD": 'Salvar cartão',
      "CANCEL": 'Cancelar',
      "NEW_CARD": "Novo cartão",
      "CREDIT_OR_DEBIT_CARD": "Cartão de crédito ou débito",
      "CREDIT_OR_DEBIT_CARD_INFO": "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis",
      "ALIAS_PLACEHOLDER": "Alias",
      "OWNER_PLACEHOLDER": "Titular",
      "PAN_PLACEHOLDER": "Nº Cartão",
      "MONTH_PLACEHOLDER": "Expira (MM)",
      "YEAR_PLACEHOLDER": "Expira (YY)",
      "ADD_CARD": "Adicionar cartão",
      "CREDITCARD_ADDED_SUCCEED" : "Cartão adicionado com sucesso",
      "CVV2": "CVV2",
      "SECURE_DATA": "Seus dados estão seguros. Paycomet é uma plataforma de nível 1 PCI-DSS, a certificação mais avançada, que criptografa os dados do cartão",
    },
  }
</i18n>

<template>
  <ModalCourtain :visibility="visibility" @close="emit('close')">
    <template v-slot:title>
      <div class="flex flex-col items-center justify-center w-full bg-white">
        <h2
          class="px-8 text-xl text-sanremo-black tracking-wider font-manrope font-bold text-center"
        >
          {{ t('NEW_CARD') }}
        </h2>
      </div>
    </template>
    <template v-slot:content>
      <div class="w-full h-full">
        <div class="md:mt-12 px-4 mx-auto max-w-lg">
          <ModalCreditCardForm
            v-if="visibility"
            :sessionId="sessionId"
            :saving="saving"
            @close="emit('close')"
            @newCreditCard="emit('newCreditCard')"
            @submit="formSubmit"
          />
          <div class="mt-4 flex items-center justify-center">
            <img
              src="@/assets/svg/icons/black/icons-lock.svg"
              class="w-2 h-2"
            />
            <p
              class="text-xs tracking-wider leading-5 md:leading-6 text-center px-2 md:px-5 text-[#717179] font-medium"
            >
              {{ t('SECURE_DATA') }}
            </p>
          </div>
          <div
            class="flex justify-center items-center px-4 space-x-4 mt-2 md:mt-4"
          >
            <img
              src="@/assets/img/banco-espana.webp"
              class="w-14 h-6 md:w-20 md:h-8"
            />
            <img
              src="@/assets/svg/icons/grey/icons-pci.svg"
              class="h-10 w-16 md:h-10 md:w-20"
            />
            <img
              src="@/assets/svg/icons/grey/icons-paycomet.svg"
              class="h-10 w-16 md:h-10 md:w-20"
            />
          </div>
        </div>
      </div>
    </template>
  </ModalCourtain>
</template>

<script setup>
const props = defineProps({
  visibility: Boolean,
  saving: Boolean,
})

const { t, locale } = useI18n()

useHead({
  link: [
    {
      rel: 'prefetch',
      as: 'script',
      href: `https://api.paycomet.com/gateway/paycomet.jetiframe.js?lang=${locale.value}`,
    },
  ],
})

const emit = defineEmits(['close', 'newCreditCard', 'submitForm'])

const formSubmit = async (data) => {
  const { alias: ccAlias, ccholder: holder, token: ccToken } = data

  emit('submitForm', { ccAlias, holder, ccToken })
}
</script>
